.menuWrapper .menuBanner{
    background: #040202;
    height: 130px;
}
.menuWrapper .menuBanner img{
    width: 100%;
    height: 100%;
}
.menuWrapper .deliveryMode{
    background: #fff;
    width: 275px;
    margin: auto;
    filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
    text-align: center;
    padding: 10px 0px;
    margin-top: -100px;
}
.menuWrapper .deliveryMode h2{
    font-weight: 700;
    font-size: 18px;
    color: #000;
    margin-bottom: 8px;
    text-transform: uppercase;
}
.modeTab .nav-tabs{
    justify-content: center;
    border-bottom: 0;
}
.modeTab .nav-tabs .nav-link.active{
    background: #000;
    color: #fff;
}
.modeTab .nav-tabs .nav-link{
    background: #E5E5E5;
    border: 0.911851px solid #4F4F4F;
    border-radius: 9px;
    font-size: 14px;
    font-weight: normal;
    color: #000;
    padding: 0px 22px;
    display: flex;
    align-items: center;
}
.modeTab .nav-tabs .nav-link img{
    margin-right: 10px;
}
.modeTab .nav-tabs .nav-item:first-child .nav-link{
    margin-right: -13px;
}
.modeTab .nav-tabs .nav-link:nth-child(2){
    margin-left: -13px;
    position: relative;
    z-index: -1;
}
.menudetailMain .menuDetails .row{
    margin: 0;
}
.menuWrapper .deliveryMode .deliveryTime{
    display: flex;
    justify-content: space-between;
    padding: 0 22px;
    margin-top: 4px;
}
.menuWrapper .deliveryMode .menuHours p{
    font-size: 9.11851px;
    margin-bottom: 0;
    font-weight: bold;
}
.menuWrapper .deliveryMode .deliveryTime p{
    margin-bottom: 6px;
    color: #121212;
    font-size: 9.11851px;
    opacity: 0.8;
}
.menuWrapper .menudetailMain{
    padding: 16px 0;
}
.menuWrapper .menudetailMain .menu_heading{
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.25px;
    margin: 0;
    padding: 0 16px;
}
.menuWrapper .menudetailMain .menuDetails{
    padding: 0 20px;
}
.menuWrapper .menudetailMain .menuDetails .menuList{
    border-bottom: 1px solid #E5E5E5;
    display: flex;
    padding: 16px 0;
    justify-content: space-between;
}
.menuWrapper .menudetailMain .menuDetails .menuList .itemList {
    height: 100%;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
}
.menuWrapper .menudetailMain .menuDetails .menuList .menuimage{
    min-width: 74px;
    max-width: 74px;
}
.menuWrapper .menudetailMain .menuDetails .menuList .menuimage img{
    width: 100%;
}
.menuWrapper .menudetailMain .menuDetails .menuList .menuimage button{
    font-size: 13px;
    color: #fff;
    background-color: #121212;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 0px;
    width: 100%;
}
.menuWrapper .menudetailMain .menuDetails .menuList .menuinfo  .itemList h6{
    font-weight: bold;
    margin-bottom: 14px;
    font-size: 14px;
}
.menuWrapper .menudetailMain .menuDetails .menuList .menuinfo  .itemList .itemPrice{
    display: flex;
    align-items: center;
}
.menuWrapper .menudetailMain .menuDetails .menuList .menuinfo  .itemList .itemPrice h2{
    margin: 0 0 0 6px;
    font-size: 14px;
    font-weight: bold;
}
.menuWrapper .menudetailMain .menuDetails .menuList .menuinfo .itemList p{
    color: #121212;
    opacity: 0.8;
    font-size: 10px;
    font-weight: 300;
}
.menuBar{
    background-color: #fff;
    box-shadow: 0px 1px 13px rgb(0 0 0);
    padding: 4px 4px 4px 3px;
    border-radius: 100%;
    width: 54px;
    min-width: 54px;
    min-height: 56px;
    height: 56px;
    position: fixed;
    bottom: 50px;
    right: 20px;
}
.menuBar .menubottom{
    width: fit-content;
    border-radius: 100%;
    background-color: #000;
    text-align: center;
    font-weight: bold;
    font-size: 10px;
    color: #fff;
    width: 47.86px;
    min-width: 47.86px;
    min-height: 48.19px;
    height: 48.19px;
    display: flex;
    flex-flow: column;
    justify-content: center;
}
.menuBar .menubottom img{
    width: fit-content;
    margin: 0 auto;
}
.menuBar .menubottom p{
    margin-bottom: 0;
}
.viewCart button{
    background: #000000;
    border-radius: 0px 0px 12px 12px;
    width: 100%;
    height: 40.91px;
    position: fixed;
    bottom: 0;
    color: #fff;
    border: 0;
    text-align: center;
    letter-spacing: 0.75px;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
}